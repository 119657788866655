export const environment = {
  production: true,
  googleAPIKey: "AIzaSyD8E5ScX2arcU2K9BvhzCchs8yMTWRd180",
  squareUrl: "https://web.squarecdn.com/v1/square.js",
  stripeClientKey: 'pk_live_51MhhRqLj0ENcrZ4AUgfoXA6hgbk8ScH0mMv1ydU51JCbAktX2AU39epDYjMItmDwWMQ3PyIIr8PEjpKQWgkaRUBG00eOrC85Gx',
  merchantId: 2,
  processor: "SQUARE",
  brand: "LOCAL",
  title: "LOCAL PUBLIC EATERY",
  titleFull: "",
  giftCardEnable: true,
  takeoutEnable: false,
  giftingEnable: true,
  giftingMessageEnable: false,
  giftingMessage: "Send a local gift box today",
  payEnable: true,
  dineInMenuEnable: false,
  dineInMenuId: 6,
  takeoutMenuId: 1,
  loginEnable: true,
  modalShort: 3000,
  modalLong: 5000,
  feeFreeThreshold: 50,
  feeFreeHintThreshold: 0,
  feeMenuId: 14,
  feeDsvcSeq: 147,
  feeMenuCustPayUnder: 10205,
  discountRefetchDelay: 5000,
  payTipDefaultPercent: 18,
  payTipPercentages: [15, 18, 20],
  deliveryDriverTipDefaultPercent: 15,
  deliveryDriverTipPercentages: [5, 10, 15],
  deliveryDriverTipMax: 10,
  deliveryLocationTipDefaultPercent: 0,
  deliveryLocationTipPercentages: [5, 10, 15],
  takeoutLocationTipDefaultPercent: 15,
  takeoutLocationTipPercentages: [15, 18, 20],
  driverTipDsvcSeq: 150,
  locationTipDsvcSeq: 149,

  mapsAPIKey: "AIzaSyD8E5ScX2arcU2K9BvhzCchs8yMTWRd180",
  iosAppUrl: "https://itunes.apple.com/us/app/local-pay/id1435831693",
  androidAppUrl:
    "https://play.google.com/store/apps/details?id=com.rklix.pay.lpe",
  firebase: {
    apiKey: "AIzaSyCRmfhkEDFF-CW6jOk1Rh5g4savb4jwbv8",
    authDomain: "web-pay-e873c.firebaseapp.com",
    databaseURL: "https://web-pay-e873c.firebaseio.com",
    projectId: "web-pay-e873c",
    storageBucket: "web-pay-e873c.appspot.com",
    messagingSenderId: "318004698461",
    appId: "1:318004698461:web:7620c246e851f038419cb4",
    measurementId: "G-LYCEKGMEJS",
  },
  dcimages: {},
  alcoholProhibitedMajorGroups: ["LIQUOR"],
  alcoholProhibitedLocationIds: [18, 22, 42],
  alcoholProhibitedMenuIds: [1],
  terms: 'https://www.xpotech.net/privacy-policy-XPO',
  discount: {
    hasDiscount: true,
    terms: [{
      header: "The Responsibility",
      terms: "If it is not right don't let it go unresolved. Give our business partners on-the-spot feedback or email j50@localpubliceatery.com. If it is right, go out of your way to thank the team and ackowledge them."
    }, {
      header: "The Rules",
      terms: (discountName: string) => `The benefits of the ${discountName} are non-transferable. Your party size should be no larger than six people when using your card. Please drink responsibly; do not drink and drive.`
    }]
  } // Should all come from an API Call at somepoint
};
